<template>
    <div class="dairy-box">
      <!-- banner部分 -->
      <div class="dairy-banner">
        <div class="dairy-bannerbox">
          <div class="dairy-leftbox">
            <div class="dairy-contentbox">
              <div class="dairy-title">牧场牛只生产管理平台</div>
              <div class="dairy-miaosu">
                UniDairy系统内置了众多的日常管理业务，和核心业务的解决方案，为牧场直接 导入日常管理任务，如巡棚管理、卧床管理、设备维护保养等，提供繁殖解决方 案，乳房炎防治解决方案等。 UniDairy是一个牧场执行力提升管理平台，不同地专家研究结果、优秀牧场的实 践方案，都将在这个平台中进行分享和推广。
              </div>
              <div class="dairy-shen">
                <!-- <el-button type="primary">申请体验</el-button> -->
                <div class="dairy-an">申请体验</div>
              </div>
            </div>
          </div>
          <div class="dairy-rightbox">
            <div class="dairy-imgbox">
              <!-- <img
                src="../../../../public/imgs/undairy/牛8 2(1).png"
                alt=""
              /> -->
            </div>
          </div>
        </div>
      </div>
      <!-- tab流程 -->
      <div class="flowpath-box">
        <div class="flowpath-tabox">
          <div
            class="flowpath-cont"
            v-for="(item, index) in list"
            :key="index"
            @click="changes(index)"
            :class="{ active: index == num }"
          >
            <div class="flowpath-imgbox">
              <img :src="item.imgUrl" alt="" />
            </div>
            <div class="flowpath-title">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <!-- 属性控制盒子 -->
  
      <div class="attribute-box">
        <div class="topnav-show" v-show="num == 0">
          <div class="topnav-lift"></div>
          <div class="topnav-right">
            <div class="topnav-contentbox">
              <div class="topnay-title">一目了然的工作台</div>
              <div class="topnay-miaosu">
                我的我的味道我的味道单位的味道哇味道我的胃味道哇的味道哇的味道味道味道打我的温度稳定户监督和大户为读物味道。
              </div>
              <div class="topnay-anniu">
                <!-- <el-button type="primary">联系我们使用</el-button> -->
                <div class="topnay-niu">联系我们使用</div>
              </div>
            </div>
          </div>
        </div>
        <div class="topnav-show" v-show="num == 1">
          <div>内容2</div>
        </div>
        <div class="topnav-show" v-show="num == 2">
          <div>内容3</div>
        </div>
        <div class="topnav-show" v-show="num == 3">
          <div>内容4</div>
        </div>
        <div class="topnav-show" v-show="num == 4">
          <div>内容5</div>
        </div>
      </div>
  
      <!-- 任务单 -->
      <div class="tasklistbox">
        <div class="task-contentbox" v-if="sheetdisplay">
          <div class="task-liftbox">
            <div class="task-box">
              <div class="task-title">日常任务</div>
              <div class="task-miaosu">
                我的我的味道我的味道单位的味道哇味道我的胃味道哇的味道哇的味道味道味道打我的温度稳定户监督和大户为读物味道。
              </div>
            </div>
          </div>
          <div class="task-rightbox"></div>
        </div>
      </div>
      <!-- 工作单 -->
      <div class="worklist">
        <div class="work-contentbox" v-if="workdisplay">
          <div class="work-liftbox"></div>
          <div class="work-rightbox">
            <div class="work-box">
              <div class="work-title">巡棚管理</div>
              <div class="work-miaosu">
                我的我的味道我的味道单位的味道哇味道我的胃味道哇的味道哇的味道味道味道打我的温度稳定户监督和大户为读物味道。
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 初始化数据 -->
      <div class="Initialized-box">
        <div class="Initialized-centerbox" v-if="Initializeddisplay">
          <div class="Initialized-liftbox">
            <div class="Ini-box">
              <div class="Ini-title">卧床管理</div>
              <div class="Ini-miaosu">
                我的我的味道我的味道单位的味道哇味道我的胃味道哇的味道哇的味道味道味道打我的温度稳定户监督和大户为读物味道。
              </div>
            </div>
          </div>
          <div class="Initialized-rightbox"></div>
        </div>
      </div>
      <!-- 完整的事件管理功能 -->
      <div class="management-box">
        <div class="management-centerbox" v-if="completedisplay">
          <div class="mana-liftbox"></div>
          <div class="mana-rightbox">
            <div class="mana-box">
              <div class="mana-title">初始化数据</div>
              <div class="mana-miaosu">
                对管理中心、常用事件、繁殖事件、疾病事件、检测评分、修改标
                记、日常其他功能进行一个简单的描述，证明我们系统当中有这些 内容。
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--可视化数据报表 -->
      <div class="perfect-box">
        <div class="perfect-centerbox" v-if="isualizationsiaplay">
          <div class="perfect-liftbox">
            <div class="per-box">
              <div class="per-title">可视化数据报表</div>
              <div class="per-miaosu">
                我的我的味道我的味道单位的味道哇味道我的胃味道哇的味道哇的味道味道味道打我的温度稳定户监督和大户为读物味道。
              </div>
            </div>
          </div>
          <div class="perfect-rightbox"></div>
        </div>
      </div>
      <!-- 可靠的人员安全配置 -->
      <div class="security-box">
        <div class="security-centerbox" v-if="usname">
          <div class="security-liftbox"></div>
          <div class="security-rightbox">
            <div class="sec-box">
              <div class="sec-title">可靠的人员安全配置</div>
              <div class="sec-miaosu">
                我的我的味道我的味道单位的味道哇味道我的胃味道哇的味道哇的味道味道味道打我的温度稳定户监督和大户为读物味道。
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 公共脚步部分 -->
      <public-footer @Immediately="Immediately"></public-footer>
    </div>
  </template>
  <script>
  import PublicFooter from "../../../components/publicfooter/index.vue";
  
  export default {
    components: {
      PublicFooter,
    },
    props: [],
    data() {
      return {
        list: [
          // {
          //   imgUrl: require("../../../../public/imgs/undairy/Group 491(1).png"),
          //   title: "控制台",
          // },
          // {
          //   imgUrl: require("../../../../public/imgs/undairy/Group 487(1).png"),
          //   title: "事件管理",
          // },
          // {
          //   imgUrl: require("../../../../public/imgs/undairy/Group 488(1).png"),
          //   title: "兽医工作台",
          // },
          // {
          //   imgUrl: require("../../../../public/imgs/undairy/Group 489(1).png"),
          //   title: "智慧芯",
          // },
          // {
          //   imgUrl: require("../../../../public/imgs/undairy/Group 490(1).png"),
          //   title: "牛群管理",
          // },
         
         
        ],
        num: 0,
        // 滚动高度
        scrollTop: "",
        // 任务单动画
        sheetdisplay: false,
        //工作单动画
        workdisplay: false,
        //初始化数据动画
        Initializeddisplay: false,
        //完整的事件管理功能动画
        completedisplay: false,
        //可视化数据报表动画
        isualizationsiaplay: false,
        //用户管理动画
        usname: false,
      };
    },
    methods: {
      changes(key) {
        this.num = key;
      },
      // 立即咨询
      Immediately() {
        this.$router.push("/fillinformation");
      },
      // 监听浏览器
      listenerFunction() {
        document.addEventListener("scroll", this.handleScroll, true);
      },
      // 获取浏览器滚动条高度
      handleScroll(event) {
        console.log("滚动条高度为：", event.target.scrollTop);
        this.scrollTop = event.target.scrollTop;
        const topObj = {
          400: "sheetdisplay",
          900: "workdisplay",
          1400: "Initializeddisplay",
          1900: "completedisplay",
          2500: "isualizationsiaplay",
          2900: "usname",
        };
        for (const top in topObj) {
          this.scrollTop >= top && (this[topObj[top]] = true);
        }
      },
    },
  
    created() {
      this.listenerFunction();
    },
    beforeDistroy() {
      document.removeEventListener("scroll", this.listenerFunction);
    },
  };
  </script>
  <style scoped lang="scss">
  // 公共动画
  @keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  // 属性控制盒子内容动画
  @keyframes pulse {
    0% {
      -webkit-transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
    }
  }
  
  
  .dairy-box {
    width: 100%;
    height: 100%;
    //banner部分
    .dairy-banner {
      width: 100%;
      height: 30rem;
      background: #f8f9fe;
  
      .dairy-bannerbox {
        width: 72.5rem;
        height: 100%;
  
        margin: 0 auto;
  
        display: flex;
        .dairy-leftbox {
          width: 50%;
          height: 100%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .dairy-contentbox {
            width: 30rem;
            height: 20rem;
            // border: 1px solid #000;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .dairy-title {
              font-size: 1.8rem;
              font-weight: 800;
              padding-left: 0.5rem;
            }
            .dairy-miaosu {
              width: 100%;
              height: 10rem;
              // border: 1px solid #000;
              // text-align: center;
              line-height: 2rem;
              font-size: 1rem;
              color: #666666;
            }
            .dairy-shen {
              width: 100%;
              height: 3rem;
              //  border: 1px solid #000;
              // line-height: 3rem;
              .dairy-an{
                width:6.375rem;
                height: 2.5rem;
                // border: 1px solid #000;
                border-radius:4%;
                color:white;
                background: linear-gradient(to right, #65B585, #54956C);
                text-align: center;
                line-height: 2.5rem;
                font-size: 0.9rem;
                cursor: pointer;
              }
              .dairy-an:hover{
                background: #1db354;
              }
            }
          }
        }
        .dairy-rightbox {
          width: 50%;
          height: 100%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .dairy-imgbox {
            width: 36rem;
            height: 20rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    //tab流程
    .flowpath-box {
      width: 100%;
      height: 5rem;
      // border: 1px solid #000;
  
      // background: yellow;
      .flowpath-tabox {
        width: 72.5rem;
        height: 100%;
        // border: 1px solid #000;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .flowpath-cont {
          width: 10rem;
          height: 2rem;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          // justify-content: space-around;
          position: relative;
          cursor: pointer;
          .flowpath-imgbox {
            width: 1.95rem;
            height: 1.87rem;
  
            border-radius: 0.375rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .flowpath-title {
            font-size: 1.2rem;
            margin-left: 1rem;
          }
        }
        .active {
          font-weight: 800;
          border-radius: 0.3125rem;
          // border-bottom: 3.5px solid #409eff;
          // background-image: linear-gradient(
          //   to top,
          //   rgba($color: #409eff, $alpha: 0.2),
          //   transparent
          // );
          .flowpath-imgbox {
            background: #65B585;
            animation: iconTrans 0.2s ease-in-out;
            box-shadow: 0.125rem 0.125rem 0.5rem #145cfe45;
  
            img {
              filter: brightness(88);
            }
          }
          @keyframes iconTrans {
            0% {
              transform: scale(0.7);
            }
            100% {
              transform: scale(1);
            }
          }
        }
        .active::after {
          position: absolute;
          bottom: -1.2rem;
          left: -22%;
          transform: translate(50%, -50%);
          content: "";
          width: 62%;
          height: 0.25rem;
          border-radius: 0.25rem;
          background: #65B585;
        }
      }
    }
    //属性控制盒子
    .attribute-box {
      width: 100%;
      height: 30rem;
      background: #f8f9fe;
      display: flex;
      justify-content: center;
      align-items: center;
      .topnav-show {
        width: 71.25rem;
        height: 82%;
        // border: 1px solid #000;
        margin: 0 auto;
        display: flex;
        animation: pulse 1s .2s ease both;
        .topnav-lift {
          width: 55%;
          height: 100%;
          // border: 1px solid #000;
          background: url("https://qncdn.uni-sheep.com/s/Group%20404%281%29.png")
            center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .topnav-right {
          width: 45%;
          height: 100%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .topnav-contentbox {
            width: 60%;
            height: 60%;
            // border: 1px solid #000;
            display: flex;
            flex-direction: column;
            // justify-content: space-around;
            margin-left: 4rem;
            .topnay-title {
              font-size: 1.2rem;
              font-weight: 800;
            }
            .topnay-miaosu {
              width: 100%;
              height: 6rem;
              // border: 1px solid #000;
              font-size: 1rem;
              line-height: 2rem;
              color: #575c62;
              margin-top: 1rem;
            }
            .topnay-anniu {
              width: 8rem;
              height: 2.8rem;
              // border: 1px solid #000;
              margin-top: 0.5rem;
              .topnay-niu{
                width:8rem;
                height: 2.5rem;
                // border: 1px solid #000;
                border-radius:4%;
                color:white;
                background: linear-gradient(to right, #65B585, #54956C);
                text-align: center;
                line-height: 2.5rem;
                font-size: 0.9rem;
                cursor: pointer;
              }
              .topnay-niu:hover{
                background: #1db354;
              }
            }
          }
        }
      }
    }
    //  任务单
    .tasklistbox {
      width: 100%;
      height: 30rem;
      // background: yellow;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .task-contentbox {
        width: 71.25rem;
        height: 82%;
        // border: 1px solid #000;
        margin: 0 auto;
        display: flex;
        animation: slide-up 1.5s ease-in-out;
        animation-fill-mode: forwards;
        .task-liftbox {
          width: 35%;
          height: 100%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .task-box {
            width: 92%;
            height: 35%;
            // border: 1px solid #000;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
  
            .task-title {
              font-size: 1.6rem;
              font-weight: 600;
            }
            .task-miaosu {
              width: 100%;
              height: 6rem;
              // border: 1px solid #000;
              font-size: 1rem;
              line-height: 2rem;
              color: #575c62;
              margin-top: 1rem;
            }
          }
        }
        .task-rightbox {
          width: 65%;
          height: 100%;
          background: url("https://qncdn.uni-sheep.com/s/Group%20406%281%29.png")
            center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
    //工作单
    .worklist {
      width: 100%;
      height: 30rem;
      // border: 1px solid #000;
      // background-color: red;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .work-contentbox {
        width:71.25rem;
        height: 82%;
        // border: 1px solid #000;
        margin: 0 auto;
        display: flex;
        animation: slide-up 1.5s ease-in-out;
        animation-fill-mode: forwards;
        .work-liftbox {
          width: 65%;
          height: 100%;
          background: url("https://qncdn.uni-sheep.com/s/Group%20377%281%29.png")
            center center;
          background-repeat: no-repeat;
        }
        .work-rightbox {
          width: 35%;
          height: 100%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .work-box {
            width: 92%;
            height: 35%;
            // border: 1px solid #000;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin-left: 1rem;
            .work-title {
              font-size: 1.6rem;
              font-weight: 600;
            }
            .work-miaosu {
              width: 100%;
              height: 6rem;
              // border: 1px solid #000;
              font-size: 1rem;
              line-height: 2rem;
              color: #575c62;
              margin-top: 1rem;
            }
          }
        }
      }
    }
    //初始化数据
    .Initialized-box {
      width: 100%;
      height: 30rem;
      // border: 1px solid #000;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .Initialized-centerbox {
        width: 71.25rem;
        height: 82%;
        // border: 1px solid #000;
        margin: 0 auto;
        display: flex;
        animation: slide-up 1.5s ease-in-out;
        animation-fill-mode: forwards;
        .Initialized-liftbox {
          width: 35%;
          height: 100%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .Ini-box {
            width: 92%;
            height: 35%;
            // border: 1px solid #000;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .Ini-title {
              font-size: 1.6rem;
              font-weight: 600;
            }
            .Ini-miaosu {
              width: 100%;
              height: 6rem;
              // border: 1px solid #000;
              font-size: 1rem;
              line-height: 2rem;
              color: #575c62;
              margin-top: 1rem;
            }
          }
        }
        .Initialized-rightbox {
          width: 65%;
          height: 100%;
          background: url("https://qncdn.uni-sheep.com/s/Group%20382%281%29.png")
            center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
    //完整的事件管理功能
    .management-box {
      width: 100%;
      height: 35rem;
      //  border: 1px solid #000;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .management-centerbox {
        width: 1140px;
        height: 90%;
        // border: 1px solid #000;
        margin: 0 auto;
        display: flex;
        animation: slide-up 1.5s ease-in-out;
        animation-fill-mode: forwards;
        .mana-liftbox {
          width: 45%;
          height: 100%;
          background: url("https://qncdn.uni-sheep.com/s/Group%20341%281%29.png")
            center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .mana-rightbox {
          width: 55%;
          height: 100%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .mana-box {
            width: 62%;
            height: 28%;
            // border: 1px solid #000;
            margin-left: 6rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .mana-title {
              font-size: 1.6rem;
              font-weight: 600;
            }
            .mana-miaosu {
              width: 100%;
              height: 6rem;
              // border: 1px solid #000;
              font-size: 1rem;
              line-height: 2rem;
              color: #575c62;
              margin-top: 1rem;
            }
          }
        }
      }
    }
    //可视化数据报表
    .perfect-box {
      width: 100%;
      height: 30rem;
      // background:blue;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .perfect-centerbox {
        width: 71.25rem;
        height: 82%;
        // border: 1px solid #000;
        margin: 0 auto;
        display: flex;
        animation: slide-up 1.5s ease-in-out;
        animation-fill-mode: forwards;
        .perfect-liftbox {
          width: 35%;
          height: 100%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .per-box {
            width: 92%;
            height: 35%;
            // border: 1px solid #000;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .per-title {
              font-size: 1.6rem;
              font-weight: 600;
            }
            .per-miaosu {
              width: 100%;
              height: 6rem;
              // border: 1px solid #000;
              font-size: 1rem;
              line-height: 2rem;
              color: #575c62;
              margin-top: 1rem;
            }
          }
        }
        .perfect-rightbox {
          width: 65%;
          height: 100%;
          background: url("https://qncdn.uni-sheep.com/s/Group%20384%281%29.png")
            center center;
          background-repeat: no-repeat;
        }
      }
    }
    //可靠的人员安全配置
    .security-box {
      width: 100%;
      height: 34rem;
      // background:yellow;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .security-centerbox {
        width: 71.25rem;
        height: 92%;
        // border: 1px solid #000;
        margin: 0 auto;
        display: flex;
        animation: slide-up 1.5s ease-in-out;
        animation-fill-mode: forwards;
        .security-liftbox {
          width: 60%;
          height: 100%;
          background: url("https://qncdn.uni-sheep.com/s/Group%20378%281%29.png")
            center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .security-rightbox {
          width: 40%;
          height: 100%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .sec-box {
            width: 80%;
            height: 35%;
            // border: 1px solid #000;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin-left: 3rem;
            .sec-title {
              font-size: 1.6rem;
              font-weight: 600;
            }
            .sec-miaosu {
              width: 100%;
              height: 6rem;
              // border: 1px solid #000;
              font-size: 1rem;
              line-height: 2rem;
              color: #575c62;
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
  </style>
  